/*header*/

@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=devanagari,latin-ext');

/* #Primary
================================================== */
html {
  scroll-behavior: smooth;
}

body{
  font-family: 'Poppins', sans-serif;
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
	color: #212112;
	background-position: center;
	background-repeat: repeat;
	background-color: #fff;
	overflow-x: hidden;
  transition: all 200ms linear;
  overflow-y: scroll;
  box-sizing: border-box;
}
::selection {
	color: #333;
	background-color: #bedeffdb;
}
::-moz-selection {
	color: #333;
	background-color: #bedeffdb;
}


.selector-for-some-widget {
  box-sizing: content-box;
}


/* body main scrolbar css starts here*/

body::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    border-radius: 0;
    background: rgb(0 0 0 / 0%);
   
}

body::-webkit-scrollbar {
    width: 8px;
    background: rgb(0 0 0 / 0%);
}

body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: none;
    background-color: #afe0ff;
}


 /* header start */

 .btn-theme{
    background: linear-gradient(117.85deg,#fe8065,#f74d6c);
    border-color: transparent;
    color: #fff !important;
    transition: all .3s ease-in-out;
    padding: 0.75rem 2.25rem  !important;
  
}

.btn-theme:hover {
  background: linear-gradient(#fff 0 0) padding-box,linear-gradient(90deg,#fe8065,#f74d6c) border-box;
  border: 1px solid transparent;
  color: #f74d6c !important;
  transition: all .3s ease-in-out;
}

.border-radius{
  border-radius: 50px;
}
 .padding-lr{
  padding-left: 50px;
  padding-right: 50px;
 }
.border-bottom{
  border-bottom: 1px solid #b5b2b233;
}

.btn-outline-theme {
  background: linear-gradient(#fff 0 0) padding-box,linear-gradient(90deg,#fe8065,#f74d6c) border-box;
  border: 1px solid transparent;
  color: #f74d6c !important;
  transition: all .3s ease-in-out;
  padding: 0.75rem 2.25rem  !important;
}


.btn-outline-theme:hover{
  background: linear-gradient(117.85deg,#fe8065,#f74d6c);
  border-color: transparent;
  color: #fff !important;
}
 /* end header */


 /* hero section */

 .bg-white {
  background-color:#fff !important;
}

.breadcrumb .breadcrumb-item {
  font-size: 20px;
  font-weight: 500;
}


.p-b-100 {
  padding-bottom: 100px;
}
.p-t-100 {
  padding-top: 100px;
}



.p-b-80 {
  padding-bottom: 80px;
}
.p-t-80 {
  padding-top: 80px;
}



.hero_heading{
    font-size: 48px;
    font-weight: 700;
}



.breadcrumb .breadcrumb-item.active, .breadcrumb .breadcrumb-item a {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(#fe8065,#f74d6c);
  -webkit-background-clip: text;
}

.breadcrumb .breadcrumb-item.active, .breadcrumb .breadcrumb-item a {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(#fe8065,#f74d6c);
  -webkit-background-clip: text;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/");
}


/* start book apponment */

.bg-secondary {
  background-color: #f3f5f8!important;
}

.rounded-20{
  border-radius: 20px;
}

.book-appointment-form {
  padding: 40px;
}

.box-shadow {
  box-shadow: 0 5px 15px #d7dee8;
}

.form-lg-check {
  height: 24px;
  margin-top: 0;
  width: 24px;
}

.me-2 {
  margin-right: 0.5rem!important;
}

.text-gray-200 {
  color: #747b81;
}
.fw-light {
  font-weight: 300 !important;
}

.form-control, .form-select {
  border-color: #babdc0;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 300;
  height: 55px;
  padding: 10px 12px;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #58585899 !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 4%) !important;
}

.form-label {
  color: #747b81;
  font-size: 14px;
  font-weight: 300;
}


.form-check-input-1 {
  width: 1.5em;
  height: 1.5em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0,0,0,.25);
  appearance: none;
  border-radius: 3px;

}

.form-check-danger, .form-check-danger:checked {
  background-color: #f62947 !important;
  border-color: #f62947 !important;
}

.form-check-success, .form-check-success:checked {
  background-color: #0ac074 !important;
  border-color: #0ac074 !important;
}

.form-check .form-check-input-1 {
  margin-left: -1.5em;
}

.me-3 {
  margin-right: 1rem!important;
}

.fs-small {
  font-size: 14px;
}

.slots-div {
  border: 1px solid #babdc0;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
  max-height: 350px;
  overflow-y: auto;
  padding: 20px;
  text-align: center;
  /* margin: 0 0 0 0; */
}

.required{
  color:#ea5151;
}


/* datepiker */

.book-appointment-page .book-appointment-form .slots-box .slots-item {
  margin: 8px !important;
  min-width: calc(20% - 15px);

}



.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge-lg {
  border-radius: 10px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  height: 36px !important;
  line-height: 24px;
  padding: 8px !important;
  margin: 4px 7px;
}

.front-slot-data .time-slot.activeSlot, .front-slot-data .time-slot:hover {
  background: #5d5dc2!important;
}


/* React changes */



.input-group-text-appoint-date {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0 8px 8px 0;
}

.input-group-cal {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
}

#cal-icon{
  border-radius: 0 0.5rem 0.5rem 0;
}

.border-cal-custom{
  border-radius: 10px 0 0 10px !important;
}


/* Responsive */

/* @media screen and (max-width: 426px){ */
@media(max-width: 426px){
  .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .p-t-80 {
    padding-top: 12px;
  }

  *header {
    height: 60px;
  }
  
  /* .header img {
    width: 90px;
    /* height: 30px; */
  /* }  */

  .ml-2, .mx-2 {
    margin-left: 1rem!important;
  }
 
  .form-check-input-1 {
    margin-left: 1.8em;
  }

  img {
    vertical-align: middle;
    border-style: none;
    width: 120px;
  }

  /* .d-flex {
    display: inline-flex!important;
    flex-wrap: wrap;
  } */

  .p-b-80 {
    padding-bottom: 40px;
  }

  .p-b-100 {
    padding-bottom: 40px;
  }

  .p-t-100 {
    padding-top: 40px;
  }

  /* .d-flex {
    display: flex!important;
    flex-wrap: nowrap;
  } */

  .d-flex {
    display: flex!important;
    flex-wrap: wrap;
  }

  .d-sm-flex-custom  {
    display: flex!important;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .mb-5, .my-5 {
    margin-bottom: 2rem!important;
  }

  .h4, h4 {
    font-size: 1.4rem;
  }

  .book-appointment-section img{
    display: none;
  }

  .custom-toggler {
    border: none;
  }

  .navbar-light .navbar-toggler {
    color: none;
    border-color: none;
    /* color: rgba(0,0,0,.5); */
    /* border-color: rgba(0,0,0,.1); */
  }
  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: none;
    box-shadow: none;
  }

  .slots-div {
    border: 1px solid #babdc0;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 10px;
    max-height: 350px;
    overflow-y: auto;
    padding: 20px;
    text-align: center;
    /* margin: 0 0 0 0; */
}

.custom-a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  /* text-align: center; */
  padding-right: 20px;
  padding-left: 5px;
  margin: auto;
}

}